<script setup lang="ts">
import { useWindowScroll, useWindowSize } from '@vueuse/core'

type VideoSource = {
  media: string
  src: string
  type: string
}

const heroVideo = ref<HTMLVideoElement | null>(null)
const { y } = useWindowScroll()
const { height } = useWindowSize()
watch([ height, y, heroVideo ], ([ height, y, heroVideo ]) => {
  if (heroVideo) {
    heroVideo.style.transform = `translateY(${y * -0.5}px)`
    heroVideo.style.opacity = `${1 - Math.min(1, (y - (height * 0.1)) / (height * 0.6))}`
  }
}, { immediate: true })
onMounted(() => {
  if (heroVideo.value) {
    heroVideo.value.playbackRate = 0.75
  }
})

const heroVideoHeights = [ 240, 360, 480, 720, 1080, 1440, 2160 ]
const videoRatio = 1.0 / 0.7
const createVideoSource = ({ height, isLargest, isSmallest, minHeight, type }: { height: number, isLargest: boolean, isSmallest: boolean, minHeight: number, type: string }) => ({
  media: isSmallest ? `all and (max-height: ${Math.floor(height * videoRatio)}px)` : isLargest ? `all and (min-height: ${Math.floor(minHeight * videoRatio)}px)` : `all and (min-height: ${Math.floor(minHeight * videoRatio)}px) and (max-height: ${Math.floor(height * videoRatio)}px)`,
  src: `/videos/bbq-ribs-${height}.${type}`,
  type: `video/${type}`,
})
const heroVideoSources = heroVideoHeights.reduce((sources, height, index, array) => {
  const minHeight = index > 0 ? (heroVideoHeights[index - 1] ?? 0) + 1 : 0
  const isSmallest = index === 0
  const isLargest = index === array.length - 1
  sources.push(
    createVideoSource({ height, isLargest, isSmallest, minHeight, type: 'mp4' }),
    createVideoSource({ height, isLargest, isSmallest, minHeight, type: 'webm' }),
  )
  return sources
}, [] as VideoSource[])
</script>

<template>
  <a href="#main-content" class="absolute left-0 top-0 bg-blue-500 text-white py-2 px-4 z-50 transform -translate-y-full focus:translate-y-0 transition">
    Skip to main content
  </a>
  <div class="fixed left-0 top-0 h-screen w-full overflow-hidden -z-10">
    <video
      ref="heroVideo"
      autoplay
      muted
      playsinline
      loop
      class="w-auto h-full object-cover"
    >
      <source
        v-for="(videoSource, index) in heroVideoSources"
        :key="index"
        :src="videoSource.src"
        :type="videoSource.type"
        :media="videoSource.media"
      >
    </video>
  </div>
  <AppHeader/>

  <UMain id="main-content">
    <slot/>
  </UMain>

  <AppFooter/>

  <UNotifications/>
</template>
